    <template>
    <section>
       <div>
        <!-- <button @click="reservation()">SUBMIT</button> -->
       </div>
    </section>
</template>

<script>
import { dateMixin } from './mixins/date'
import  reservations  from './../json/reservations.json'
import axios from 'axios';
export default {
    name: 'test',
    mixins:[dateMixin],
    components: {
    },
    data () {
        return {
            reservations : reservations.data,
            events:{
                start:"2023-11-03T09:30.00.000Z",
                end:"2023-11-03T09:45.00.000Z",
            },
            dateend:"2023-11-03T09:45:00.000Z",

            date:"2011-10-05T14:48:00.000Z",
            date3:'',
            img:''
            }
    },
    computed: {

    },
    methods:{
        reservation(){
            let body = {
                "description":"reservation",
                "reservation_date":"2023-09-01T12:12+02:00",
                "staff":"01FJ1RX663SKW9TDJ9P7Y0WNDJ",
                "sites":"01FP7D1Y68GN16J8SZHAABCPA5",
                "on_behalf_of":{"name":"Manu Bussutil",
                "phone":"","email":"email"
                },
                "address":{
                    "name":"Mon échappée belle Gaillac","street1":"64 Av. Saint-Exupery","city":"Gaillac","state":"FR","zip":"81 600","country":"FR","phone":"06 75 43 75 22","email":"contact@monechappeebelle.fr"
                    },
                    "appointments":[
                    { "start_hour":"2023-10-21 08:00:00","end_hour":"2023-10-21 08:20:00","service":"01FNEJ0YJ8X6JSQQBZRSK4394X"}
                    ]
                }
                axios.post('myreservations',body,{
                    headers:{
                        "X-AUTH-TOKEN": localStorage.getItem('token')
                    }
                })
                .then(resReservation => {
                    console.log({resReservation}) 
                })
                .catch(errReservation =>{
                    // liste des erreurs et messages d'erreur
                    let status_code = errReservation.response.status
                    let error_response = errReservation.response.data
                    if(status_code === 400) {
                        if(error_response.error=="stackable") {
                            alert("Votre réservation se superpose avec une autre, merci de réserver sur un autre créneau");
                        }else if(error_response.message === "appointments cannot be empty" || error_response.message === "appointments cannot be null" || error_response.message ==="reservation must have at least one appointment"){
                            alert("Merci de ne pas laisser la section prestation vide")
                        }else{
                            alert("Une erreur s'est produite, le contenu de votre réservation n'a pas été pris en compte, Veuillez réessayer")
                        }
                    }else if(status_code === 404){
                        if(error_response.message === "services not found"){
                            alert('Prestation non trouvée, code: 404 services not found')
                        }
                        else if (error_response.message === "start_hour not found") {
                            alert('Date de début non définie')
                        }
                        else if (error_response.message === "end_hour not found") {
                            alert('Date de fin non définie')
                        }else{
                            alert('Une erreur 404 est survenue')
                        }
                    }else if(status_code === 500){
                        alert('Une erreur 500 est survenue, merci de bien vouloir nous contacter');
                    }else{
                        alert('Une erreur est survenue dans myreservations, merci de bien vouloir actualiser la page et réessayer')
                    }
                })
        }

    },
    mounted() {
    }
}
</script>
<style scoped>
img{
    /* forme des images */
    border-radius: 50%;
    width: 13rem;
    height: 200px;
    object-fit: cover;
    
}
</style>